import { defineStore } from "pinia"
import BasketProductQuantity from "../components/basket/models/BasketProductQuantity"
import EventBus from "../bus"
import Events from "../components/basket/utils/events"
import Product from "../models/Product"

export const useBasketStore = defineStore("basket", {
	state: () => ({
		_basket: new Map(),
		_products: new Map(),
	}),

	getters: {
		products(state) {
			return Array.from(state._products.values())
		},

		basketCount(state) {
			let count = 0

			state._basket.forEach((productQuantity) => {
				count += productQuantity.quantity
			})

			return count
		},
	},

	actions: {
		add(basketObject) {
			if (!basketObject || !basketObject.productId) return

			const existingBasketProduct = this._basket.get(basketObject.productId)

			// If product not in basket - add with quantity
			// If product already in basket - increment quantity
			const newQuantity = existingBasketProduct
				? existingBasketProduct.quantity + basketObject.quantity
				: basketObject.quantity
			const newBasketProductQuantity = new BasketProductQuantity(
				basketObject.productId,
				newQuantity,
			)

			this._basket.set(basketObject.productId, newBasketProductQuantity)

			const existingProduct = this._products.get(basketObject.productId)
			if (existingProduct) {
				const existingProductData = new Product(
					existingProduct.id,
					existingProduct.name,
					existingProduct.image,
					existingProduct.size,
				)

				EventBus.$emit(Events.basketUpdated, existingProductData)
				return
			}

			// Only fetch product data if product not in store
			if (!existingProduct || existingProduct === undefined) {
				fetch(`/umbraco/api/basket/product/${basketObject.productId}`)
					.then((response) => response.json())
					.then((product) => {
						const newProductData = new Product(
							product.id,
							product.name,
							product.image,
							product.size,
						)

						// Add the product data to the store so we don't have to fetch it again
						this._products.set(Number(product.productId), newProductData)

						EventBus.$emit(Events.basketUpdated, newProductData)
					})
					.catch((error) => {
						console.error("Error adding product to basket:", error)
					})
			}
		},
	},
})
