<template>
    <nav class="2xl:container p-4 sm:px-8" :aria-label="navLabel">
      <ol class="md:flex items-center flex-wrap">
        <li v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="flex items-center">
  
          <!-- chevron -->
          <svg v-if="index > 0 && index < breadcrumbs.length && !breadcrumb.hidden" xmlns="http://www.w3.org/2000/svg" :class="['w-2 h-2 mx-1', svgClass]">
            <use :href="iconHref('chevronRight')" />
          </svg>
  
          <!-- breadcrumb -->
          <template v-if="breadcrumb.expandButton === undefined">
            <a v-if="breadcrumbs.length !== 1 && index < breadcrumbs.length - 1" :href="breadcrumb.href"
               :class="['text-xs leading-none whitespace-nowrap', { 'invisible w-0': breadcrumb.hidden }, textClass]">
              {{ breadcrumb.title }}
            </a>
  
            <!-- current page -->
            <span v-else :class="['text-xs leading-none whitespace-nowrap', { 'text-white opacity-80': !whiteBackground }]"
                  aria-current="page">
              {{ breadcrumb.title }}
            </span>
          </template>
  
        </li>
      </ol>
    </nav>
  </template>
  
  <script>
    import IconChevronRight from "../../../assets/icons/chevron-right.svg"
    
    const iconMap = {
      'chevronRight': IconChevronRight,
    }
    
    export default {
      name: "IntBreadcrumb",
      
      props: {
        breadcrumbs: { type: Array, required: true },
        whiteBackground: { type: Boolean, default: true },
        navLabel: { type: String, default: 'Breadcrumb' },
        showAllLabel: { type: String, default: 'Show all pages' }
      },
      
      computed: {
        textClass() {
          return this.whiteBackground ? 'text-black' : 'text-white'
        },
        
        svgClass() {
          return !this.whiteBackground ? 'fill-white' : ''
        }
      },
      
      methods: {
    
        iconHref(key) {
          if (iconMap[key]) return '#' + iconMap[key].id
          return null
        },

      }
    }
  </script>