<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 26 26"
    enable-background="new 0 0 26 26"
    xml:space="preserve"
  >
    <path
      d="M24.32,10.6c-0.17-0.23-0.43-0.37-0.72-0.37h-6.72c0.14,0.58,0.14,1.19,0,1.78h5.5l-3.02,9.08H6.61L3.6,12.01h5.49
	c-0.14-0.58-0.14-1.19,0-1.78H2.37c-0.49,0-0.89,0.4-0.89,0.89c0,0.09,0.02,0.19,0.04,0.28l3.6,10.86c0.12,0.36,0.46,0.61,0.84,0.61
	h14.04c0.38,0,0.72-0.25,0.84-0.61l3.6-10.86C24.54,11.13,24.49,10.83,24.32,10.6"
    />
    <path
      d="M12.99,13.52c1.33,0,2.4-1.08,2.39-2.41c0-0.32-0.06-0.63-0.19-0.92l6.41-6.41c0.35-0.35,0.35-0.91,0-1.26
	s-0.91-0.35-1.26,0l0,0l-6.4,6.4c-1.21-0.54-2.63,0-3.17,1.21s0,2.63,1.21,3.17C12.3,13.45,12.64,13.52,12.99,13.52"
    />
  </svg>
</template>
