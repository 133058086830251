<template>
  <div>
    <div v-if="addressLines && addressLines.length > 0" class="inline-flex">
      <span class="w-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" fill="#2F2F2F" height="28">
          <use :href="iconHref('MarkerIcon')"/>
        </svg>
      </span>

      <address class="mb-5 pl-3 leading-7 not-italic text-sm md:text-base">
        <span v-for="(addressLine, index) in addressLines" :key="'addressline-' + index">{{ addressLine }}<br></span>
      </address>
    </div>

    <div v-if="phone || email || hasSocialLinks" class="pb-10">
      <div v-if="phone">
        <a :href="'tel:' + phone"
           class="md:text-lg mb-5 focus:outline-none focus:underline hover:underline inline-flex items-center">
        <span class="w-10">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" fill="#2F2F2F" height="23">
            <use :href="iconHref('PhoneIcon')"/>
          </svg>
        </span>

          <span class="pl-3">{{ phone }}</span>
        </a>
      </div>

      <div v-if="email">
        <a :href="'mailto:' + email"
           class="md:text-lg focus:outline-none focus:underline hover:underline inline-flex items-center">
        <span class="w-10">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" fill="#2F2F2F" height="23">
            <use :href="iconHref('EmailIcon')"/>
          </svg>
        </span>

          <span class="pl-3">{{ email }} </span>
        </a>
      </div>

      <div v-if="hasSocialLinks" class="mt-5">
        <div v-for="(link, index) in socialLinks" :key="'sociallink' + index">
          <a :href="link.url"
             :target="link.target"
             class="inline-flex items-center mb-5 md:text-lg focus:outline-none focus:underline hover:underline">
          <span class="w-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" fill="#2F2F2F" height="23">
              <use :href="iconHref(link.icon)"/>
            </svg>
          </span>

            <span class="pl-3">{{ link.name }} </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EmailIcon from '../../../assets/icons/email-icon-slim.svg'
  import FacebookIcon from '../../../assets/icons/facebook.svg'
  import InstagramIcon from '../../../assets/icons/instagram.svg'
  import LinkedinIcon from '../../../assets/icons/linkedin.svg'
  import MarkerIcon from '../../../assets/icons/marker-icon.svg'
  import PhoneIcon from '../../../assets/icons/phone-icon-slim.svg'
  import TwitterIcon from '../../../assets/icons/twitter.svg'
  import YoutubeIcon from '../../../assets/icons/youtube.svg'
  
  const iconMap = {
    'EmailIcon': EmailIcon,
    'facebook': FacebookIcon,
    'instagram': InstagramIcon,
    'linkedin': LinkedinIcon,
    'MarkerIcon': MarkerIcon,
    'PhoneIcon': PhoneIcon,
    'twitter': TwitterIcon,
    'youtube': YoutubeIcon
  }

  export default {
    name: "IntAddress",
  
    props: {
      addressLines: { type: Array },
      phone: { type: String},
      email: { type: String },
      socialLinks: { type: Array }
    },
  
    computed: {
      hasSocialLinks() {
        return this.socialLinks && this.socialLinks.length > 0
      }
    },

    methods: {
      iconHref(key) {
        if (iconMap[key]) return '#' + iconMap[key].id

        return null
      }
    }
  }
</script>