<template>
  <TransitionRoot v-if="showPopover" appear :show="showPopover" as="template">
    <Dialog as="div" @close="onClosePopoverClick" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
                       leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25"></div>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template"
                           enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100"
                           leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="w-full max-w-xl relative transform overflow-hidden rounded-3xl bg-white p-6 md:p-10 lg:p-20 text-left align-middle shadow-xl transition-all">
              <button type="button" @click="onClosePopoverClick" class="absolute top-4 right-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#A5A5A5">
                  <use :href="iconHref('PlusIcon')" />
                </svg>

                <span class="sr-only">{{ closeLabel }}</span>
              </button>

              <div v-if="showNewWishlistForm">
                <DialogTitle as="h3" class="normal-case text-3xl mb-5">{{ newWishlistTitle }}</DialogTitle>

                <div class="mb-2">
                  <label for="NewWishlistName" class="text-sm text-black">{{ newWishlistLabel }}</label>
                </div>
                    
                <div class="mb-4">
                  <input type="text" id="NewWishlistName" name="NewWishlistName" ref="newWishlistNameInput" :placeholder="newWishlistPlaceholder"
                         @input="updateCreateWishlistButtonDisabledState"
                         :class="['w-full mb-2 p-3 bg-gray-100 border-[1px] text-sm text-black placeholder:text-black focus:outline-none', showValidation ? 'border-red' : 'border-l-sand focus:ring-blue focus:border-blue']"
                         aria-describedby="newWishlistInputError" />
                  <p v-if="showValidation" id="newWishlistInputError" class="text-xs text-red">{{ wishlistNameValidationMessage }}</p>
                </div>

                <div class="text-center">
                  <IntNavButton @click="onCreateNewWishlistClick" color="tint" class="w-full sm:w-auto uppercase disabled:opacity-50 transition-opacity" :disabled="newWishlistFormInvalid">
                    {{ wishlistCreateButtonLabel }}
                  </IntNavButton>
                </div>
              </div>
              <div v-else>
                <DialogTitle as="h3" class="normal-case text-3xl mb-5">{{ addRemoveWishlistTitle }}{{ wishlists.length > 0 && wishlists.length !== 1 ? 's' : '' }}</DialogTitle>

                <ul v-if="wishlists.length > 0">
                  <li v-for="wishlist in wishlists" :key="wishlist.id" class="mb-2 py-2 px-4 bg-gray-100 rounded-sm md:flex md:items-center">
                      <a :href="wishlistDetailUrl + '?id=' + wishlist.id" class="block">
                        {{ wishlist.name }}
                      </a>

                    <div class="mt-2 md:mt-0 md:ml-auto">
                      <button type="button" v-if="showAddButton(wishlist.id)" @click="onAddButtonClick(wishlist.id)"
                              :class="['text-blue-800', { ' border-r-black border-r-[1px] mr-2 pr-2' : wishlistHasAnyProducts(wishlist.id) }]">
                        {{ addButtonText }}
                      </button>
                      
                      <button type="button" v-if="showRemoveButton(wishlist.id)" @click="onRemoveButtonClick(wishlist.id)" class="text-blue-800">
                        {{ removeButtonText }}
                      </button>
                    </div>
                  </li>
                </ul>
                <p v-else>{{ noWishlistsText }}</p>

                <div class="mt-4 text-center">
                  <IntNavButton @click="onShowNewWishlistFormClick" color="tint" class="w-full sm:w-auto uppercase">{{ wishlistCreateButtonLabel }}</IntNavButton>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  } from '@headlessui/vue'
</script>

<script>
  import { mapActions, mapState, mapStores } from 'pinia'
  import { useWishlistStore } from '../../../../stores/wishlist'
  
  import ProductQuantityUtil from '../../utils/product-quantity'
  
  import EventBus from '../../../../bus'
  import Events from '../../events'
  
  import IntNavButton from '../../../nav-button'

  import PlusIcon from '../../../../../assets/icons/plus-icon.svg'

  const iconMap = {
    'PlusIcon': PlusIcon
  }
  
  export default {
    name: 'IntWishlistModal',
    
    components: { IntNavButton },
    
    props: {
      wishlistDetailUrl: { type: String, required: true },
      closeLabel: { type: String, default: 'Close' },
      newWishlistTitle: { type: String, default: 'Create new Wishlist' },
      newWishlistLabel: { type: String, default: 'Wishlist name' },
      newWishlistPlaceholder: { type: String, default: 'Wishlist title here' },
      wishlistNameValidationMessage: { type: String, default: 'Please enter a name for this wishlist' },
      wishlistCreateButtonLabel: { type: String, default: 'Create wishlist' },
      addRemoveWishlistTitle: { type: String, default: 'Add/remove from Wishlist' },
      addButtonText: { type: String, default: 'Add' },
      removeButtonText: { type: String, default: 'Remove' },
      noWishlistsText: { type: String, default: 'You do not have any wishlists. Click the button below to create your first wishlist.' }
    },
    
    data() {
      return {
        showPopover: false,
        showNewWishlistForm: false,
        newWishlistFormInvalid: true,
        newWishlistFormInteracted: false,
        productIds: null,
        collectionId: null,
        quantity: 1
      }
    },

    computed: {
      ...mapStores(useWishlistStore),
      ...mapState(useWishlistStore, ['wishlists']),
      
      showValidation() {
        return this.newWishlistFormInvalid && this.newWishlistFormInteracted
      }
    },
    
    created() {
      EventBus.$on(Events.addOrRemoveProducts, this.onAddOrRemoveProductsEvent)
      EventBus.$on(Events.wishlistUpdated, this.onWishlistUpdatedEvent)
      EventBus.$on(Events.createWishlist, this.onCreateWishlistEvent)
    },
    
    methods: {
      ...mapActions(useWishlistStore, ['addProducts', 'removeProducts']),

      /**
       * Set data values based on emitted product data. 
       * @param {Array<ProductQuantity>} e - Array of ProductQuantity.
       */
      onAddOrRemoveProductsEvent(e) {
        this.productIds = e.productIds
        this.collectionId = e.collectionId
        this.quantity = e.quantity
        
        this.togglePopover(true)
      },

      onWishlistUpdatedEvent() {
        this.onClosePopoverClick()
      },

      onCreateWishlistEvent() {
        this.showNewWishlistForm = true
        this.togglePopover(true)
      },

      showAddButton(wishlistId) {
        if (!this.productIds) return false
        
        if (this.productIds.length === 1) return true
        
        return !this.wishlistStore.hasAllProducts(wishlistId, this.productIds)
      },
      
      showRemoveButton(wishlistId) {
        return this.productIds && this.wishlistHasAnyProducts(wishlistId)
      },
      
      onAddButtonClick(wishlistId) {
        const productsEvent = ProductQuantityUtil.createProductQuantityEvent(this.productIds, this.collectionId, this.quantity)
        this.wishlistStore.addProducts(wishlistId, productsEvent)
      },

      onRemoveButtonClick(wishlistId) {
        this.wishlistStore.removeProducts(wishlistId, this.productIds, this.collectionId)
      },
      
      wishlistHasAnyProducts(wishlistId) {
        return this.wishlistStore.hasAnyProducts(wishlistId, this.productIds)
      },

      onShowNewWishlistFormClick() {
        this.showNewWishlistForm = true
        
        this.$nextTick(() => {
          this.$refs.newWishlistNameInput.focus()
        })
      },

      updateCreateWishlistButtonDisabledState() {
        this.newWishlistFormInteracted = true
        this.newWishlistFormInvalid = this.$refs.newWishlistNameInput.value.trim() === ''
      },

      onCreateNewWishlistClick() {
        this.wishlistStore.addWishlist(this.$refs.newWishlistNameInput.value)
        
        this.showNewWishlistForm = false
        this.newWishlistFormInvalid = true
        this.newWishlistFormInteracted = false
        
        if (this.productIds === null) this.togglePopover(false)
      },

      /**
       * Reset the state of the modal to add/remove wishlist, in case in showNewForm mode, and close popover
       */
      onClosePopoverClick() {
        this.productIds = null
        this.collectionId = null
        this.showNewWishlistForm = false
        this.newWishlistFormInvalid = true
        this.newWishlistFormInteracted = false
        
        this.togglePopover(false)
      },
      
      togglePopover(show) {
        this.showPopover = show
      },

      iconHref(key) {
        if (iconMap[key]) return '#' + iconMap[key].id
        return null
      }
    }
  }
</script>