<template>
  <PopoverGroup class="inline-block">
    <Popover class="inline-block group lg:relative">
      <PopoverButton class="h-10 w-10 ml-2 lg:ml-0 xl:ml-2 active-blue">
        <svg xmlns="http://www.w3.org/2000/svg"
             class="h-[18px] sm:w-[23px] inline-block group-data-[headlessui-state=open]:fill-blue-600 hover:fill-blue-600"
             width="20.49"
             height="20.592"
             fill="#a5a5a5">
          <use v-if="memberHasWishlists" :href="iconHref('HeartIconFilled')" />
          <use v-else :href="iconHref('HeartIcon')" />
        </svg>

        <span v-if="memberHasWishlists" class="ml-[2px] text-sm">{{ wishlists.length }}</span>

        <span class="sr-only">{{ title }}</span>
      </PopoverButton>

      <PopoverOverlay class="fixed z-1 inset-0 bg-black opacity-30" :class="['top-[75px]', headerIsReduced ? 'md:top-[75px]' : 'md:top-[107px]']" />

      <Transition>
        <PopoverPanel v-slot="{ close }" class="absolute right-0 lg:-right-4 w-full flex flex-row justify-center items-center lg:w-auto lg:text-right mt-10 md:mt-14 lg:mt-3 px-6 lg:px-0 xl:-right-9">
          <div class="relative w-96 p-8 bg-white rounded-3xl shadow-md">
            <svg class="hidden -top-[20px] lg:right-5 xl:right-10 lg:inline-block lg:absolute lg:group-data-[headlessui-state=open]:inline-block"
                 xmlns="http://www.w3.org/2000/svg"
                 width="38"
                 height="22"
                 viewBox="0 0 38 22">
              <defs>
                <filter id="Polygon_1" x="0" y="0" width="38" height="22"
                        filterUnits="userSpaceOnUse">
                  <feOffset dy="-2" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="1" result="blur" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>

              <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Polygon_1)">
                <path id="Polygon_1-2" data-name="Polygon 1" d="M16,0,32,16H0Z" transform="translate(3 5)" fill="#fff" />
              </g>
            </svg>
            
            <button @click="onCloseButtonClick(close)">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#A5A5A5">
                <use :href="iconHref('PlusIcon')" />
              </svg>

              <span class="sr-only">{{ closeLabel }}</span>
            </button>
            
            <h6 class="normal-case text-center text-2xl mb-4">{{ title }}</h6>

            <ul v-if="memberHasWishlists" class="mb-6 border-t text-left">
              <li v-for="wishlist in latestWishlists" :key="'wishlist-' + wishlist.id">
                <a :href="wishlistDetailUrl + '?id=' + wishlist.id" class="block border-b py-2">
                  {{ wishlist.name }}
                </a>
              </li>
              <li v-if="wishlists.length > 2" class="block border-b py-2">
                <a :href="wishlistListUrl">View all</a>
              </li>
            </ul>
            <p v-else class="mb-6 text-center">{{ noWishlistsText }}</p>

            <IntNavButton @click="onCreateWishlistButtonClick" color="tint" fit-content class="uppercase">
              {{ newWishlistButtonText }}
            </IntNavButton>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  </PopoverGroup>
</template>

<script setup>
  import { Popover, PopoverButton, PopoverGroup, PopoverOverlay, PopoverPanel } from '@headlessui/vue'
</script>

<script>
  import { mapState } from 'pinia'
  import { useWishlistStore } from '../../../../stores/wishlist'

  import EventBus from '../../../../bus'
  import Events from '../../events'
  
  import IntNavButton from '../../../nav-button'
  
  import HeartIcon from '../../../../../assets/icons/heart-icon.svg'
  import HeartIconFilled from '../../../../../assets/icons/heart-icon-fill.svg'
  import PlusIcon from '../../../../../assets/icons/plus-icon.svg'
  
  const iconMap = {
    'HeartIcon': HeartIcon,
    'HeartIconFilled': HeartIconFilled,
    'PlusIcon': PlusIcon
  }
  
  export default {
    name: 'IntWishlistAccount',
    
    components: { IntNavButton },

    props: {
      headerId: { type: String, required: true },
      title: { type: String, default: 'My Wishlists' },
      wishlistListUrl: { type: String, required: true },
      wishlistDetailUrl: { type: String, required: true },
      closeLabel: { type: String, default: 'Close' },
      noWishlistsText: { type: String, default: 'You do not have any wishlists. Click the button below to create your first wishlist.' },
      newWishlistButtonText: { type: String, default: 'Create new wishlist' },
      headerIsReduced: { type: Boolean, required: true }
    },
    
    data() {
      return {
        show: false
      }
    },

    computed: {
      ...mapState(useWishlistStore, ['wishlists', 'latestWishlists']),
      
      memberHasWishlists() {
        return this.wishlists.length > 0
      }
    },
    
    methods: {
      onCloseButtonClick(close) {
        close()
      },
      
      onCreateWishlistButtonClick() {
        EventBus.$emit(Events.createWishlist)
        
        this.show = false
      },
      
      iconHref(key) {
        if (iconMap[key]) return '#' + iconMap[key].id
        return null
      }
    }
  }
</script>