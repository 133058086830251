<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 668.15 226.62"
    enable-background="new 0 0 668.15 226.62"
    xml:space="preserve"
  >
    <path
      fill="#203768"
      d="M189.2,106.74c-0.72,0.73-1.22,1.26-1.76,1.76c-1.61,1.53-3.21,3.06-4.84,4.56c-2.18,1.99-4.4,3.94-6.6,5.93
	c-1.34,1.2-2.66,2.45-4.1,3.77l-3.21-0.41c-0.16-0.02-0.32-0.09-0.48-0.1c-1.76-0.1-3.53-0.2-5.3-0.3
	c-2.21-0.14-4.42-0.33-6.63-0.43c-1.48-0.07-2.97-0.03-4.46-0.02c-3.51,0-7.02-0.02-10.53,0.03c-2.33,0.03-4.66,0.14-6.99,0.26
	c-2.75,0.14-5.5,0.3-8.25,0.48c-0.53,0.03-1.05,0.17-1.58,0.2c-2.5,0.19-5,0.32-7.5,0.55c-1.59,0.14-3.16,0.43-4.75,0.63
	c-0.45,0.06-0.9,0.03-1.36,0.08c-1.92,0.21-3.83,0.43-5.75,0.64c-1.14,0.13-2.29,0.22-3.43,0.35c-1.8,0.21-3.57,0.46-5.36,0.68
	c-1.5,0.19-3,0.36-4.52,0.55c-0.74,0.05-1.47,0.15-2.19,0.3c-1.56,0.33-3.14,0.57-4.73,0.72c-2.79,0.42-5.57,0.94-8.36,1.4
	c-1.7,0.28-3.4,0.52-5.1,0.8c-0.44,0.07-1.04,0.17-1.55,0.3c0.03,0.52,0.12,1.04,0.27,1.55c0.58,2.1,1.46,4.1,2.61,5.95
	c1.72,2.67,3.82,5.08,6.23,7.15c3.67,3.21,8.01,5.55,12.72,6.83c2.17,0.6,4.33,1.24,6.53,1.73c2.47,0.57,4.98,1,7.5,1.27
	c1.75,0.18,3.08,0.37,4.82,0.62c2.18,0.14,3.9,0.07,5.62,0.11c0.32,0,0.96-0.03,1.29,0c0.26,0.04,0.52,0.06,0.79,0.06
	c1.02-0.05,1.67-0.06,2.7-0.06c1.89,0,3.36-0.15,5.42-0.33c0.2-0.04,0.81-0.11,1.15-0.14c1.73-0.13,3.12-0.24,4.82-0.51
	c1.62-0.26,3.24-0.51,4.86-0.75c3.32-0.5,6.61-1.21,9.85-2.1c3.52-0.96,7.02-2,10.52-3.03c1.1-0.32,2.18-0.73,3.25-1.14
	c2.61-0.99,5.24-1.94,7.8-3.03c3.6-1.52,7.14-3.16,10.71-4.75c0.33-0.15,0.65-0.35,1.04-0.57c0.16,0.17,0.29,0.35,0.4,0.56
	c0.6,1.62,1.16,3.24,1.73,4.87c0.15,0.42,0.3,0.85,0.47,1.3c-0.22,0.64-0.87,0.86-1.37,1.13c-3.17,1.73-6.35,3.43-9.57,5.07
	c-2.59,1.32-5.21,2.61-7.87,3.78c-2.93,1.29-5.92,2.45-8.9,3.62c-2.18,0.85-4.37,1.67-6.6,2.38c-3.2,1.02-6.38,2.1-9.67,2.84
	c-2.48,0.55-4.93,1.22-7.41,1.76c-1.68,0.37-3.38,0.64-5.07,0.9c-2.1,0.34-4.2,0.67-6.3,0.95c-1.14,0.15-2.29,0.23-3.44,0.29
	c-2.14,0.11-4.35,0.28-6.41,0.3c-1.5,0.02-3.58,0.11-5.05,0.06c-1.88-0.07-2.96-0.04-4.84-0.18c-0.25-0.02-0.76-0.04-1.33-0.06
	c-1.73-0.07-3.46-0.24-5.18-0.51c-2.07-0.3-3.42-0.54-5.27-0.9c-1.85-0.36-3.67-0.84-5.49-1.34s-3.63-1.04-5.42-1.63
	c-1.29-0.42-2.55-0.92-3.78-1.49c-2.94-1.29-5.79-2.77-8.54-4.43c-2.09-1.3-4.09-2.75-5.97-4.35c-1.75-1.5-3.37-3.14-4.87-4.9
	c-1.48-1.82-2.8-3.76-3.93-5.81c-0.8-1.48-1.43-3.05-1.86-4.67c-0.38-1.29-0.62-2.62-0.94-4.02c-0.49-0.52-1.2-0.33-1.8-0.26
	c-1.01,0.11-2.02,0.3-3.02,0.5c-2.61,0.54-5.18,1.28-7.68,2.2c-2.93,1.08-5.8,2.33-8.58,3.75c-2.17,1.08-4.27,2.28-6.4,3.43
	c-0.65,0.35-1.3,0.72-1.96,1.04c-0.27,0.1-0.56,0.18-0.85,0.22c-0.6-0.44-1.05-1.06-1.28-1.78c0.41-1.43,1.05-2.78,1.91-3.99
	c0.46-0.68,0.83-1.42,1.34-2.06c1.38-1.74,2.89-3.36,4.53-4.86c3.45-3.07,7.38-5.56,11.62-7.38c2.27-1.05,4.64-1.89,7.07-2.5
	c1.63-0.41,3.25-0.85,4.87-1.29c0.19-0.07,0.37-0.15,0.54-0.24c0.43-1.63,0.9-3.2,1.25-4.8c0.51-1.94,1.18-3.84,2.01-5.67
	c1.22-2.92,2.74-5.7,4.55-8.3c1.23-1.8,2.57-3.52,4.04-5.13c2.89-3.11,5.86-6.14,8.89-9.12c1.64-1.61,3.42-3.06,5.2-4.5
	c1.84-1.5,3.72-2.97,5.64-4.38c2.42-1.77,4.98-3.34,7.65-4.71c3.93-1.97,7.98-3.68,12.13-5.14c3.43-1.26,6.93-2.32,10.48-3.19
	c1.67-0.41,3.3-0.97,4.99-1.32c2.89-0.6,5.79-1.1,8.7-1.59c2.14-0.36,4.29-0.67,6.44-0.96c1.18-0.16,2.37-0.22,3.56-0.32
	c1.64-0.14,3.27-0.34,4.91-0.42c1.77-0.08,3.55-0.07,5.32-0.07c3.39,0,6.77,0,10.16,0.05c1.27,0.02,2.54,0.18,3.8,0.3
	c1.67,0.14,3.33,0.27,5,0.44c1.34,0.13,2.7,0.3,4.03,0.49c0.97,0.13,1.78,0.23,2.9,0.46c2.15,0.44,4.28,0.76,6.37,1.32
	c3.09,0.85,6.11,1.91,9.05,3.2c1.08,0.47,2.1,1.07,3.15,1.61c-2.25,2.58-4.64,5.04-7.18,7.34c-2.47,2.28-4.82,4.71-7.2,7.06
	l-5.43-2.13c-2.91-1.23-5.9-2.28-8.94-3.13c-2.03-0.52-4-1.2-6.03-1.72c-1.93-0.54-3.9-0.94-5.9-1.2c-0.9-0.09-1.77-0.34-2.67-0.44
	c-2.48-0.28-4.98-0.48-7.46-0.79c-3.4-0.38-6.84-0.43-10.25-0.16c-2.24,0.15-3.97,0.37-5.74,0.6c-1.88,0.26-3.73,0.55-5.6,0.83
	c-1.34,0.19-2.66,0.48-3.96,0.85c-3.41,0.92-6.75,2.07-9.99,3.45c-2.41,1.05-4.74,2.28-6.96,3.68c-1.95,1.22-3.81,2.58-5.56,4.08
	c-2.48,2.18-4.9,4.41-7.2,6.78c-1.66,1.82-3.21,3.74-4.62,5.76c-1.56,2.12-2.92,4.37-4.07,6.74c-1.33,2.69-2.41,5.49-3.21,8.38
	c-0.13,0.47-0.21,0.95-0.22,1.44c0.42-0.02,0.84-0.07,1.26-0.17c2.33-0.45,4.66-0.92,6.98-1.4c1.68-0.35,3.36-0.76,5.04-1.09
	c1.61-0.32,3.23-0.56,4.84-0.86c1.41-0.26,2.81-0.58,4.22-0.84c2.17-0.41,4.35-0.79,6.52-1.19c1.05-0.19,2.1-0.38,3.15-0.56
	c1.33-0.22,2.67-0.44,4-0.64c1.34-0.2,2.68-0.36,4.02-0.55c2.19-0.3,4.38-0.64,6.57-0.94c2.48-0.34,4.96-0.66,7.44-0.96
	c1.14-0.14,2.29-0.19,3.44-0.3c2.4-0.23,4.8-0.48,7.22-0.7c1.23-0.11,2.45-0.19,3.69-0.26c1.72-0.1,2.92-0.24,4.64-0.33
	c1.48-0.08,3.04-0.21,4.5-0.33c1.22-0.1,2.89-0.19,4.11-0.28c1.07-0.08,2.15-0.04,3.22-0.06c3.05-0.08,6.09-0.19,9.14-0.26
	c1.4-0.03,2.64-0.09,4.42-0.04c1.85,0,3.9,0.21,5.95,0.34c1.88,0.12,3,0.3,5.25,0.48c2.12,0.23,3.69,0.56,5.37,0.72
	C186.43,106.26,187.67,106.51,189.2,106.74z"
    />
    <path
      fill="#203768"
      d="M342.91,104.02c0.16-0.89,0.08-1.8-0.24-2.64c-0.61-2.09-1.56-4.07-2.8-5.87c-1.23-1.71-2.71-3.21-4.39-4.47
	c-1.78-1.39-3.67-2.63-5.66-3.71c-2.01-1.19-4.14-2.16-6.35-2.88c-1.49-0.5-3-0.91-4.54-1.24c-1.03-0.27-2.1-0.4-3.14-0.57
	c-3.91-0.58-7.88-0.74-11.82-0.48c-1.96,0.13-3.92,0.4-5.85,0.78c-2.86,0.55-5.67,1.34-8.4,2.37c-2.78,1.03-5.39,2.47-7.74,4.27
	c-0.98,0.68-1.89,1.45-2.74,2.29c-1.25,1.35-2.4,2.81-3.52,4.27c-1.05,1.43-1.85,3.02-2.38,4.71c-0.3,1.11-1.43,1.77-2.54,1.47
	c-0.22-0.06-0.42-0.15-0.61-0.27c-2.55-1.15-5.12-2.24-7.68-3.35c-1.5-0.65-3-1.3-4.46-1.92c-0.44-0.57-0.14-1.02,0.13-1.41
	c0.86-1.25,1.7-2.53,2.66-3.7c2.06-2.51,4.47-4.73,7.16-6.57c2.84-1.97,5.91-3.58,9.15-4.78c1.97-0.72,3.94-1.38,5.96-1.94
	c2.41-0.66,4.87-1.14,7.35-1.44c2.48-0.32,4.97-0.52,7.46-0.79c4.07-0.32,8.16-0.38,12.23-0.18c1.14,0,2.22,0.08,3.36,0.14
	c0.6,0.03,1.27,0.09,2.13,0.16c1.68,0.11,3.35,0.33,4.99,0.66c0.8,0.15,1.64,0.26,2.45,0.36c0.81,0.1,1.64,0.26,2.44,0.44
	c2.08,0.44,4.16,0.91,6.23,1.41c3.91,0.93,7.74,2.19,11.44,3.76c3.38,1.39,6.62,3.11,9.67,5.13c2.14,1.46,4.07,3.21,5.75,5.19
	c1.42,1.7,2.6,3.6,3.48,5.63c0.46,1.1,0.75,2.26,0.86,3.45c0.23,3.04,0.3,6.09,0.2,9.14c0.02,16.54,0.02,33.09,0,49.63
	c0,0.28-0.02,0.57-0.04,0.85c-0.11,0.07-0.23,0.13-0.36,0.16c-4.37,0.08-8.73,0.18-13.1,0.2c-1.97,0-4.05-0.03-6.1-0.13
	c-0.04-0.24,0-0.65,0-0.92c-0.02-0.37,0-0.74,0-1.11c-0.28-10.72-0.21-21.43-0.23-32.15c0-1.6-0.14-3.2-0.24-4.8
	c-0.09-0.86-0.39-1.68-0.88-2.4c-1.58-2.46-3.58-4.64-5.89-6.43c-2.97-2.28-6.24-4.15-9.71-5.56c-2.8-1.28-5.72-2.28-8.73-2.96
	c-1.24-0.27-2.5-0.46-3.75-0.66c-3.28-0.4-6.58-0.46-9.87-0.19c-1.96,0.15-3.9,0.48-5.8,0.99c-3.73,0.86-7.32,2.23-10.68,4.07
	c-1.91,1.05-3.72,2.27-5.4,3.65c-1.55,1.22-3.02,2.55-4.39,3.97c-2.18,2.25-3.85,4.96-4.88,7.92c-0.34,1.18-0.54,2.4-0.6,3.63
	c-0.17,2.26,0.02,4.53,0.54,6.73c0.48,2.13,1.38,4.15,2.66,5.93c1.33,1.79,2.88,3.4,4.61,4.8c1.58,1.41,3.36,2.58,5.27,3.5
	c1.74,0.92,3.53,1.74,5.37,2.45c1.81,0.84,3.71,1.47,5.67,1.86c0.56,0.07,1.57,0.39,2.13,0.41c3.92,0.52,7.88,0.7,11.82,0.53
	c2.3-0.09,4.6-0.25,6.88-0.52c1.77-0.2,3.52-0.54,5.24-1.01c1.92-0.54,3.21-0.68,4.85-1.24c0.77-0.26,2.24-0.68,2.97-0.94
	c0.62,1.2,0.33,0.71,1.66,7.66c-0.1,0.07-0.22,0.13-0.34,0.17c-1.59,0.38-3.19,0.75-4.8,1.1c-1.04,0.22-2.1,0.38-3.15,0.56
	c-1.41,0.24-2.83,0.46-4.24,0.68c-1.5,0.23-3,0.44-4.5,0.65c-0.12,0.02-0.25,0.02-0.37,0.03c-2.67,0.11-5.35,0.23-8.02,0.3
	c-1.69,0.05-3.39,0.02-5.08,0c-2.54-0.05-5.78-0.3-7.75-0.51c-1.89-0.19-3.77-0.49-5.63-0.9c-2.1-0.43-4.09-0.94-6.15-1.5
	c-3.14-0.82-6.21-1.91-9.16-3.27c-4.26-1.93-8.23-4.43-11.81-7.45c-1.37-1.15-2.68-2.38-3.9-3.68c-2.72-2.66-4.65-6.02-5.58-9.71
	c-0.65-2.3-0.9-4.69-0.75-7.07c0.12-2.26,0.66-4.47,1.59-6.54c1.83-3.79,4.48-7.12,7.75-9.77c3.54-2.89,7.47-5.27,11.66-7.09
	c3.3-1.5,6.72-2.62,10.13-3.79c4.01-1.29,8.13-2.25,12.3-2.86c1.66-0.28,3.32-0.52,4.98-0.71c1.39-0.16,2.86-0.34,4.25-0.43
	c2.17-0.14,4.27-0.26,6.44-0.26c2.17,0,4.24,0.15,6.46,0.3c0.3,0.02,1.01,0.05,1.3,0.06c0.97,0.04,3.58,0.38,5.49,0.69
	c1.54,0.25,3.08,0.47,4.59,0.85c1.59,0.39,3.19,0.74,4.77,1.16c3.22,0.85,6.35,1.99,9.36,3.4
	C342.31,103.85,342.6,103.95,342.91,104.02z"
    />
    <path
      fill="#203768"
      d="M215.34,162.29c-6.6,0.02-13.14,0-19.81,0c-0.1-0.25-0.17-0.5-0.2-0.76c-0.02-0.45,0.06-0.9,0.06-1.35
	c0-31.07,0-62.13,0-93.2c0-0.44,0.03-0.89,0.05-1.43h19.67c0.2,0.19,0.23,0.22,0.26,0.25c0.03,0.03,0.04,0.07,0.05,0.11
	c0.03,0.16,0.05,0.32,0.06,0.49c0,31.77,0,63.54,0,95.31C215.44,161.9,215.4,162.09,215.34,162.29z"
    />
    <path
      fill="#203768"
      d="M223.69,84.37h20.22v77.81c-1.33,0.2-19.16,0.17-20.04-0.03c-0.03-0.03-0.06-0.06-0.08-0.09
	c-0.03-0.03-0.04-0.07-0.05-0.11c-0.02-0.3-0.05-0.57-0.05-0.86C223.69,135.55,223.69,110.02,223.69,84.37z"
    />
    <path
      fill="#C01818"
      d="M289.7,40.78c-0.26,0.25-0.55,0.47-0.85,0.67c-1.07,0.52-2.15,1.02-3.23,1.5c-4.18,1.93-8.22,4.15-12.1,6.62
	c-3.51,2.2-6.96,4.5-10.34,6.9c-2.45,1.73-4.8,3.6-7.11,5.51c-2.86,2.35-5.65,4.78-8.45,7.2c-0.84,0.72-1.66,1.47-2.46,2.24
	c-3.11,3-6.21,6.02-9.3,9.04c-0.16,0.14-0.33,0.28-0.5,0.4c-0.59,0.02-0.81-0.36-1.1-0.65c-1.29-1.27-2.58-2.56-3.86-3.84
	c-1.85-1.85-3.69-3.72-5.56-5.56c-1.61-1.58-3.24-3.13-4.85-4.71c-0.31-0.35-0.59-0.72-0.85-1.1c5.43-0.31,10.81,1.18,15.31,4.24
	c0.64,0.27,1.24-0.18,1.84-0.63c1.64-1.23,3.27-2.49,4.93-3.69c2.36-1.7,4.71-3.45,7.15-5.03c3.2-2.08,6.48-4.04,9.75-6
	c3.54-2.09,7.18-3.99,10.91-5.7c3.56-1.69,7.22-3.18,10.95-4.45c2.68-0.9,5.38-1.74,8.08-2.59
	C288.51,41.02,288.98,40.94,289.7,40.78z"
    />
    <path
      id="Path_182"
      fill="#C01818"
      d="M355.44,78.77c-0.11-4.99,3.84-9.13,8.83-9.24c0.17,0,0.33,0,0.5,0
	c4.97-0.24,9.19,3.58,9.44,8.55c0.01,0.24,0.01,0.49,0.01,0.73c0.06,5.04-3.99,9.18-9.03,9.24c-0.11,0-0.22,0-0.34,0
	c-5.07,0.12-9.27-3.88-9.39-8.95C355.44,78.99,355.43,78.88,355.44,78.77z M364.79,86.36c4,0.15,7.36-2.97,7.51-6.97
	c0.01-0.25,0.01-0.5-0.01-0.74c0.16-4-2.95-7.37-6.94-7.53c-0.18-0.01-0.36-0.01-0.54,0c-4-0.15-7.36,2.97-7.51,6.97
	c-0.01,0.24-0.01,0.47,0.01,0.71c-0.23,3.95,2.79,7.35,6.75,7.58c0.25,0.01,0.49,0.02,0.74,0V86.36z"
    />
    <path
      id="Path_183"
      fill="#C01818"
      d="M361.37,73.43c1.76-0.03,3.52,0.03,5.27,0.19c1.51,0.11,2.64,1.42,2.53,2.93
	c-0.02,0.26-0.07,0.52-0.17,0.76c-0.16,0.87-0.77,1.58-1.61,1.86c-0.26,0.1-0.5,0.24-0.86,0.42l2.6,4.24
	c-0.45,0.45-0.93,0.19-1.4,0.3c-1.1-1.42-2.11-2.91-3.04-4.45h-1.77c-0.03,1.45-0.05,2.82-0.08,4.32l-1.42,0.09
	c-0.11-0.24-0.19-0.5-0.23-0.76c-0.01-3-0.01-6,0-9C361.23,74.02,361.29,73.72,361.37,73.43z M362.96,78.05
	c0.82,0.02,1.5,0.02,2.19,0.06c0.95,0.11,1.86-0.41,2.25-1.29l-0.32-1.4c-1.3-0.53-2.73-0.7-4.11-0.47L362.96,78.05z"
    />
    <path
      fill="#24356B"
      d="M258.53,209.75c-26.6,0-50.98-4.88-65.13-13.72c-0.7-0.44-0.92-1.36-0.48-2.07c0.44-0.7,1.37-0.92,2.07-0.48
	c28.68,17.91,104.3,18.72,148.38-5.91c45.58-25.47,69.03-34.68,69.26-34.77c0.77-0.3,1.64,0.08,1.94,0.85
	c0.3,0.77-0.08,1.64-0.85,1.94c-0.23,0.09-23.53,9.25-68.89,34.6C320.72,203.66,288.24,209.75,258.53,209.75z"
    />
    <path
      fill="#E3DED1"
      d="M650.16,124.75l-20.47-96.27c-2.07-9.73-11.63-15.93-21.36-13.86L410.27,56.74
	c-9.73,2.07-15.93,11.63-13.86,21.36l20.47,96.27c2.07,9.73,11.63,15.93,21.36,13.86L636.3,146.1
	C646.03,144.03,652.23,134.47,650.16,124.75z M436.45,175.32c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S439.77,175.32,436.45,175.32
	z"
    />
    <path
      id="Path_669"
      fill="#24356B"
      d="M437.3,115.28l-3.78,0.8l-5.75-27.03l16.87-3.59l0.71,3.32l-13.09,2.78l1.79,8.4l12.25-2.61
	l0.69,3.25l-12.25,2.61L437.3,115.28z M456.16,111.27l-4.2,0.89l5.97-29.52l3.44-0.73l17.35,24.56l-4.28,0.91l-4.16-6.06
	l-12.83,2.73L456.16,111.27z M458.08,100.45l10.12-2.15l-7.73-11.6L458.08,100.45z M505.68,96.35c-0.79,1.71-1.96,3.22-3.41,4.41
	c-1.7,1.36-3.72,2.27-5.86,2.66c-1.95,0.43-3.96,0.49-5.93,0.16c-1.83-0.3-3.57-0.98-5.13-1.98c-1.54-1.02-2.87-2.34-3.89-3.88
	c-1.12-1.67-1.89-3.55-2.29-5.52c-0.45-1.98-0.49-4.02-0.14-6.01c0.33-1.83,1.03-3.57,2.06-5.11c1.04-1.53,2.38-2.84,3.93-3.85
	c1.68-1.09,3.55-1.85,5.51-2.25c0.94-0.2,1.89-0.31,2.84-0.33c0.96-0.02,1.93,0.05,2.88,0.23c0.91,0.16,1.8,0.44,2.65,0.81
	c0.81,0.36,1.55,0.84,2.22,1.42l-2.49,2.92c-0.94-0.82-2.07-1.4-3.29-1.67c-1.34-0.34-2.75-0.36-4.1-0.07
	c-1.47,0.29-2.86,0.89-4.07,1.77c-1.11,0.81-2.04,1.84-2.73,3.03c-0.68,1.2-1.13,2.52-1.32,3.89c-0.2,1.44-0.15,2.91,0.16,4.33
	c0.3,1.46,0.84,2.85,1.62,4.13c0.71,1.18,1.65,2.21,2.76,3.02c1.1,0.8,2.36,1.36,3.7,1.63c1.45,0.29,2.95,0.27,4.4-0.06
	c1.44-0.29,2.8-0.9,3.97-1.8c1.12-0.87,1.99-2.02,2.53-3.33L505.68,96.35z M521.58,97.36l-3.77,0.8l-5.04-23.71l-8.67,1.84
	l-0.71-3.32l21.11-4.49l0.71,3.32l-8.66,1.84L521.58,97.36z M558.98,75.24c0.44,1.96,0.49,3.99,0.13,5.98
	c-0.33,1.83-1.02,3.58-2.04,5.14c-1.03,1.55-2.36,2.88-3.92,3.9c-1.67,1.1-3.54,1.87-5.5,2.27c-1.95,0.43-3.96,0.49-5.93,0.16
	c-3.71-0.6-7-2.72-9.09-5.85c-1.12-1.67-1.89-3.55-2.29-5.52c-0.45-1.97-0.5-4.01-0.16-6.01c0.31-1.82,1-3.56,2.03-5.1
	c1.04-1.53,2.37-2.83,3.91-3.84c3.4-2.16,7.46-3.03,11.45-2.43c1.83,0.29,3.58,0.94,5.16,1.92c1.56,0.99,2.91,2.3,3.95,3.83
	C557.8,71.36,558.59,73.25,558.98,75.24z M554.89,76.11c-0.29-1.43-0.84-2.79-1.61-4.03c-0.73-1.17-1.68-2.2-2.79-3.02
	c-1.11-0.81-2.38-1.37-3.73-1.66c-2.95-0.57-6.01,0.08-8.47,1.8c-1.11,0.81-2.03,1.84-2.71,3.03c-0.68,1.2-1.13,2.52-1.32,3.89
	c-0.2,1.44-0.15,2.91,0.16,4.33c0.3,1.45,0.85,2.83,1.63,4.08c0.72,1.18,1.67,2.2,2.79,3.02c1.11,0.79,2.37,1.35,3.7,1.63
	c1.45,0.29,2.94,0.28,4.38-0.05c1.46-0.29,2.84-0.88,4.06-1.74c1.12-0.79,2.06-1.82,2.75-3c0.69-1.2,1.14-2.52,1.32-3.89
	C555.26,79.04,555.2,77.55,554.89,76.11z M572.7,86.48l-3.78,0.8l-5.75-27.03l8.66-1.84c1.24-0.27,2.52-0.4,3.79-0.39
	c1.14,0,2.28,0.21,3.35,0.63c1,0.4,1.88,1.04,2.57,1.87c0.77,0.97,1.29,2.11,1.51,3.33c0.46,1.77,0.16,3.65-0.81,5.2
	c-1.03,1.53-2.51,2.7-4.24,3.33l10.19,10.8l-4.58,0.97l-9.42-10.56l-4.05,0.86L572.7,86.48z M569.47,71.29l4.34-0.92
	c0.83-0.17,1.65-0.42,2.43-0.76c0.68-0.28,1.3-0.68,1.85-1.17c0.49-0.44,0.85-1,1.07-1.62c0.22-0.7,0.24-1.45,0.06-2.16
	c-0.12-0.72-0.45-1.4-0.93-1.95c-0.44-0.47-0.99-0.83-1.6-1.04c-0.67-0.22-1.37-0.32-2.08-0.3c-0.8,0.02-1.59,0.12-2.37,0.28
	l-4.61,0.98L569.47,71.29z M604.54,79.71l-3.82,0.81l-2.48-11.68l-13.38-13.2l4.66-0.99l10.08,10.64l4.92-13.83l4.4-0.94l-6.86,17.5
	L604.54,79.71z M481.25,134.63c0.44,1.96,0.49,3.99,0.13,5.98c-0.33,1.83-1.02,3.58-2.04,5.14c-1.03,1.55-2.36,2.88-3.92,3.9
	c-1.67,1.1-3.54,1.87-5.5,2.27c-1.95,0.43-3.96,0.49-5.93,0.16c-3.71-0.6-7-2.72-9.09-5.85c-2.21-3.41-3.09-7.51-2.45-11.53
	c0.31-1.82,1.01-3.56,2.03-5.1c1.03-1.53,2.36-2.84,3.91-3.84c1.67-1.09,3.53-1.85,5.49-2.24c1.95-0.44,3.97-0.5,5.95-0.19
	c1.83,0.29,3.58,0.94,5.16,1.92c1.56,1,2.9,2.3,3.93,3.84C480.06,130.75,480.85,132.64,481.25,134.63z M477.17,135.5
	c-0.29-1.43-0.84-2.79-1.61-4.03c-0.73-1.17-1.68-2.2-2.79-3.02c-1.11-0.81-2.38-1.37-3.73-1.66c-1.47-0.3-2.98-0.29-4.44,0.05
	c-1.46,0.29-2.83,0.89-4.03,1.76c-1.11,0.81-2.03,1.84-2.71,3.03c-0.68,1.2-1.13,2.52-1.32,3.89c-0.2,1.44-0.15,2.91,0.16,4.33
	c0.3,1.45,0.85,2.83,1.63,4.08c0.72,1.18,1.67,2.2,2.79,3.02c1.11,0.79,2.37,1.35,3.7,1.63c1.45,0.29,2.94,0.28,4.38-0.05
	c1.46-0.29,2.84-0.88,4.06-1.74c1.12-0.79,2.06-1.82,2.75-3c0.69-1.2,1.14-2.52,1.32-3.89C477.53,138.43,477.48,136.94,477.17,135.5
	L477.17,135.5z M501.65,145.17c-1.59,0.38-3.24,0.41-4.85,0.09c-1.37-0.28-2.67-0.86-3.79-1.69c-1.08-0.81-1.98-1.83-2.65-3.01
	c-0.69-1.2-1.19-2.51-1.48-3.87l-3.62-17.02l3.77-0.8l3.57,16.8c0.2,0.92,0.51,1.81,0.92,2.66c0.4,0.83,0.96,1.57,1.64,2.18
	c0.69,0.61,1.52,1.05,2.42,1.28c2.21,0.43,4.51-0.05,6.36-1.35c0.74-0.57,1.33-1.31,1.72-2.16c0.37-0.84,0.58-1.74,0.61-2.66
	c0.04-0.94-0.04-1.88-0.24-2.8l-3.57-16.8l3.78-0.8l3.62,17.02c0.29,1.35,0.36,2.75,0.22,4.12c-0.14,1.34-0.55,2.65-1.2,3.83
	c-0.69,1.22-1.64,2.27-2.78,3.08C504.77,144.21,503.25,144.86,501.65,145.17L501.65,145.17z M529.75,138.48l-3.78,0.8l-5.04-23.71
	l-8.66,1.84l-0.71-3.33l21.11-4.49l0.71,3.32l-8.66,1.84L529.75,138.48z M546.98,131.3l12.06-2.57l0.71,3.36l-15.85,3.37
	l-5.75-27.04l3.79-0.81L546.98,131.3z M568.4,126.74l14.12-3l0.71,3.36l-17.9,3.81l-5.75-27.04l17.32-3.68l0.71,3.32l-13.55,2.88
	l1.72,8.09l12.75-2.71l0.69,3.26l-12.75,2.71L568.4,126.74z M599.64,123.61l-3.77,0.8l-5.04-23.71l-8.66,1.84l-0.71-3.33l21.11-4.49
	l0.71,3.32l-8.66,1.84L599.64,123.61z"
    />
    <path
      fill="#24356B"
      d="M375.36,207.65c-5.75,0-11.99-0.5-18.76-1.63c-14.7-2.44-27.17-5.01-38.17-7.28
	c-37.72-7.77-62.64-12.91-124.09-2.82c-0.82,0.16-1.59-0.42-1.72-1.24c-0.13-0.82,0.42-1.59,1.24-1.72c62-10.18,87.14-5,125.19,2.84
	c10.98,2.26,23.42,4.82,38.06,7.26c25.85,4.3,47.53-0.23,62.7-13.1c11.47-9.73,15.31-20.91,15.35-21.02
	c0.26-0.79,1.12-1.21,1.9-0.95c0.79,0.26,1.21,1.11,0.95,1.89C437.86,170.31,424.81,207.65,375.36,207.65z"
    />
  </svg>
</template>
