<template>
  <div class="col-span-3">
    <h4 v-if="title" class="text-xl font-normal my-4">{{ title }}</h4>

    <ul :class="['uppercase', { 'my-4' : !title }]">
      <li v-for="link in links">
        <a :href="link.url" :target="link.target" class="py-1 block hover:underline focus:underline">{{ link.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'IntSubNavigation',
    
    props: {
      links: { type: Array, required: true },
      title: { type: String }
    }
  }
</script>