import { useBasketStore } from "./stores/basket"
import { useWishlistStore } from "../scripts/stores/wishlist"
import { createApp } from "vue"
import { createPinia } from "pinia"
import { RegisterComponents } from "./components"
import preloadWishlistHelper from "../scripts/components/wishlists/utils/preload-wishlists"
import preloadBasket from "./components/basket/utils/preload-basket"

const pinia = createPinia()
const app = createApp({})

RegisterComponents(app)

app.use(pinia)

const wishlistStore = useWishlistStore(pinia) // pass the instance to our store, instead of inferring it from our Vue app
const basketStore = useBasketStore(pinia)
const appId = "app"

// Preload wishlist data into store
const wishlistsMap = preloadWishlistHelper.preloadWistData(appId)
if (wishlistsMap) {
	wishlistStore.$state._wishlists = wishlistsMap
}

// Preload basket data into store
const basketMap = preloadBasket.preloadData(appId)
if (basketMap) {
	basketStore.$state._basket = basketMap
}

app.mount(document.getElementById(appId))
