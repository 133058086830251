import { defineAsyncComponent } from "vue"

import IntAddress from "../components/address"
import IntBreadcrumb from "../components/breadcrumb"
import IntButton from "../components/nav-button"
import IntContentSection from "../components/content-section"
import IntFooter from "../components/footer"
import IntHeader from "../components/header"
import IntMegaDropdown from "../components/header/modules/mega-dropdown"
import IntNavigationPromo from "../components/header/modules/navigation-promo"
import IntResponsiveImage from "../components/responsive-image"
import IntSkipLinks from "../components/skip-links"
import IntSubNavigation from "../components/header/modules/sub-navigation"
import IntUmbracoFormsScriptLoader from "../components/umbracoforms-script-loader"

const Chip = defineAsyncComponent(() => import("../components/chip"))
const IntAccordion = defineAsyncComponent(() => import("../components/accordion"))
const IntAccordionItem = defineAsyncComponent(
	() => import("../components/accordion/modules/accordion-item"),
)
const IntAnchorOffset = defineAsyncComponent(() => import("../components/anchor-offset"))
const IntBanner = defineAsyncComponent(() => import("../components/banner"))
const IntBlockQuote = defineAsyncComponent(() => import("../components/block-quote"))
const IntContentContainer = defineAsyncComponent(() => import("../components/content-container"))
const IntContentGrid = defineAsyncComponent(() => import("../components/content-grid"))
const IntControlField = defineAsyncComponent(() => import("../components/form/modules/control"))
const IntControlGroup = defineAsyncComponent(
	() => import("../components/form/modules/control/group"),
)
const IntFileField = defineAsyncComponent(() => import("../components/form/modules/file"))
const IntForm = defineAsyncComponent(() => import("../components/form"))
const IntFormItem = defineAsyncComponent(() => import("../components/form/modules/form-item"))
const IntGridColumn = defineAsyncComponent(
	() => import("../components/content-grid/grid-column.vue"),
)
const IntInfoBanner = defineAsyncComponent(() => import("../components/info-banner"))
const IntInputField = defineAsyncComponent(() => import("../components/form/modules/input"))
const IntMediaSection = defineAsyncComponent(() => import("../components/media-section"))
const IntMediaSource = defineAsyncComponent(() => import("../components/media-source"))
const IntNewsListing = defineAsyncComponent(() => import("../components/news-listing"))
const IntNewsListingDisplay = defineAsyncComponent(
	() => import("../components/news-listing/modules/display"),
)
const IntNewsListingLoadMore = defineAsyncComponent(
	() => import("../components/news-listing/modules/load-more"),
)
const IntNewsListingPagination = defineAsyncComponent(
	() => import("../components/news-listing/modules/pagination"),
)
const IntPageHero = defineAsyncComponent(() => import("../components/page-hero"))
const IntProductDetail = defineAsyncComponent(() => import("../components/product-detail"))
const IntProductInfo = defineAsyncComponent(() => import("../components/product-info"))
const IntProductTile = defineAsyncComponent(() => import("../components/product-tile"))
const IntProductPricing = defineAsyncComponent(() => import("../components/product-pricing"))
const IntProductSlider = defineAsyncComponent(() => import("../components/product-slider"))
const IntProductListing = defineAsyncComponent(() => import("../components/product-listing"))
const IntPromo = defineAsyncComponent(() => import("../components/promo"))
const IntSearch = defineAsyncComponent(() => import("../components/search"))
const IntSelectField = defineAsyncComponent(() => import("../components/form/modules/select"))
const IntSliderUneven = defineAsyncComponent(() => import("../components/slider-uneven"))
const IntStoreProductPricing = defineAsyncComponent(
	() => import("../components/product-pricing/store-product.vue"),
)
const IntTextareaField = defineAsyncComponent(() => import("../components/form/modules/textarea"))
const IntTile = defineAsyncComponent(() => import("../components/tile"))
const IntValidationSummary = defineAsyncComponent(
	() => import("../components/form/modules/validation-summary"),
)
const IntWishlistActions = defineAsyncComponent(() => import("../components/wishlist-actions"))
const IntWishlistButton = defineAsyncComponent(
	() => import("../components/wishlists/modules/button"),
)
const IntWishlistDetail = defineAsyncComponent(
	() => import("../components/wishlists/modules/detail"),
)
const IntWishlistList = defineAsyncComponent(() => import("../components/wishlists/modules/list"))

const _registerComponents = (vueApp) => {
	if (vueApp) {
		vueApp.component("chip", Chip)
		vueApp.component("int-accordion", IntAccordion)
		vueApp.component("int-accordion-item", IntAccordionItem)
		vueApp.component("int-address", IntAddress)
		vueApp.component("int-anchor-offset", IntAnchorOffset)
		vueApp.component("int-banner", IntBanner)
		vueApp.component("int-block-quote", IntBlockQuote)
		vueApp.component("int-breadcrumb", IntBreadcrumb)
		vueApp.component("int-button", IntButton)
		vueApp.component("int-content-container", IntContentContainer)
		vueApp.component("int-content-grid", IntContentGrid)
		vueApp.component("int-content-section", IntContentSection)
		vueApp.component("int-control-field", IntControlField)
		vueApp.component("int-control-group", IntControlGroup)
		vueApp.component("int-file-field", IntFileField)
		vueApp.component("int-footer", IntFooter)
		vueApp.component("int-form-item", IntFormItem)
		vueApp.component("int-form-wrapper", IntForm)
		vueApp.component("int-grid-column", IntGridColumn)
		vueApp.component("int-header", IntHeader)
		vueApp.component("int-info-banner", IntInfoBanner)
		vueApp.component("int-input-field", IntInputField)
		vueApp.component("int-media-section", IntMediaSection)
		vueApp.component("int-media-source", IntMediaSource)
		vueApp.component("int-mega-dropdown", IntMegaDropdown)
		vueApp.component("int-navigation-promo", IntNavigationPromo)
		vueApp.component("int-news-listing", IntNewsListing)
		vueApp.component("int-news-listing-display", IntNewsListingDisplay)
		vueApp.component("int-news-listing-load-more", IntNewsListingLoadMore)
		vueApp.component("int-news-listing-pagination", IntNewsListingPagination)
		vueApp.component("int-page-hero", IntPageHero)
		vueApp.component("int-product-detail", IntProductDetail)
		vueApp.component("int-product-info", IntProductInfo)
		vueApp.component("int-product-tile", IntProductTile)
		vueApp.component("int-product-pricing", IntProductPricing)
		vueApp.component("int-product-slider", IntProductSlider)
		vueApp.component("int-product-listing", IntProductListing)
		vueApp.component("int-promo", IntPromo)
		vueApp.component("int-search", IntSearch)
		vueApp.component("int-responsive-image", IntResponsiveImage)
		vueApp.component("int-select-field", IntSelectField)
		vueApp.component("int-skip-links", IntSkipLinks)
		vueApp.component("int-slider-uneven", IntSliderUneven)
		vueApp.component("int-store-product-pricing", IntStoreProductPricing)
		vueApp.component("int-sub-navigation", IntSubNavigation)
		vueApp.component("int-textarea-field", IntTextareaField)
		vueApp.component("int-tile", IntTile)
		vueApp.component("int-umbracoforms-script-loader", IntUmbracoFormsScriptLoader)
		vueApp.component("int-validation-summary", IntValidationSummary)
		vueApp.component("int-wishlist-actions", IntWishlistActions)
		vueApp.component("int-wishlist-button", IntWishlistButton)
		vueApp.component("int-wishlist-detail", IntWishlistDetail)
		vueApp.component("int-wishlist-list", IntWishlistList)
	}
}

export const RegisterComponents = _registerComponents
