const postWishlists = (wishlists) => {
    const wishlistData = Array.from(wishlists.values()).map(({ id, name, created, items, discount }) => {
        const mappedItems = Array.from(items.values()).map(({ productId, collectionId, quantity }) => ({ productId, collectionId, quantity }))
        return {
            id,
            name,
            created,
            items: mappedItems,
            discount
        }
    })
    
    return fetch('/umbraco/api/wishlist/save', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(wishlistData)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Failed to post wishlists: ${response.statusText}`)
            }
        })
        .catch(error => { console.error('Error while posting wishlists:', error) })
}

const emailWishlist = (emailData) => {
    return fetch('/umbraco/api/wishlist/email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailData)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Failed to email wishlist: ${response.statusText}`)
            }
        })
        .catch(error => { console.error('Error while emailing wishlist:', error) })
}

export default {
    postWishlists,
    emailWishlist
}