<template>
	<header
		id="page-header"
		class="sticky top-0 z-100 bg-white"
		:class="['h-[75px] transition-[height]', isReduced ? 'md:h-[75px]' : 'md:h-[107px]']"
	>
		<div id="headerDiv" ref="headerDiv">
			<Popover
				ref="headerPadding"
				:class="[
					'2xl:container',
					'mx-auto',
					'px-5',
					'lg:px-8',
					'xl:px-10',
					isReduced ? 'py-4' : 'py-4 md:py-8',
					'transition-[padding]',
				]"
			>
				<div class="grid grid-cols-12 gap-0">
					<div class="col-span-4 flex flex-row items-center lg:col-span-2">
						<button
							class="group justify-center justify-self-center p-2 lg:hidden"
							:class="{ opened: mobileMenuOpen }"
							@click="mobileMenuOpen = !mobileMenuOpen"
						>
							<span class="block group-[.opened]:hidden">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									fill="#a5a5a5"
									height="16"
								>
									<use :href="iconHref('OpenMenuIcon')" />
								</svg>
							</span>

							<span class="hidden group-[.opened]:block">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									fill="#a5a5a5"
									height="16"
								>
									<use :href="iconHref('CloseMenuIcon')" />
								</svg>
							</span>
						</button>

						<a :href="homepageUrl" class="mx-2 lg:mx-0">
							<FactoryShopLogo
								v-if="isFactoryShop"
								:class="[
									'h-8 transition-[height]',
									isReduced ? 'lg:h-8' : 'lg:-my-3.5 lg:h-[62px]',
								]"
							></FactoryShopLogo>
							<TradeShopLogo
								v-else
								class="w-14 lg:w-16"
								:class="[isReduced ? 'lg:w-30' : 'lg:w-24', 'transition-[width]']"
							></TradeShopLogo>
						</a>
					</div>

					<div class="col-span-8 hidden items-center pt-[3px] lg:flex">
						<div class="w-full text-center">
							<PopoverGroup v-for="page in pages" class="inline-block px-3 pl-0">
								<Popover
									v-if="
										(page.subNavigations && page.subNavigations.length > 0) ||
										(page.promos && page.promos.length > 0)
									"
									class="group"
								>
									<PopoverButton
										class="menu-item lg:text-md relative h-10 text-sm uppercase outline-none hover:underline focus:underline xl:text-base"
									>
										{{ page.title
										}}<span
											class="arrow hidden lg:group-data-[headlessui-state=open]:inline-block"
										>
											<svg
												class="absolute -bottom-2.5 left-1/2 z-30 -translate-x-1/2 transform"
												style="right: 2.3rem"
												xmlns="http://www.w3.org/2000/svg"
												width="38"
												height="22"
												viewBox="0 0 38 22"
											>
												<defs>
													<filter
														:id="page.title"
														x="0"
														y="0"
														width="38"
														height="22"
														filterUnits="userSpaceOnUse"
													>
														<feOffset dy="-2" input="SourceAlpha" />
														<feGaussianBlur
															stdDeviation="1"
															result="blur"
														/>
														<feFlood flood-opacity="0.161" />
														<feComposite operator="in" in2="blur" />
														<feComposite in="SourceGraphic" />
													</filter>
												</defs>

												<g
													transform="matrix(1, 0, 0, 1, 0, 0)"
													:filter="`url(#${page.title})`"
												>
													<path
														:id="page.title"
														data-name="Polygon 1"
														d="M16,0,32,16H0Z"
														transform="translate(3 5)"
														fill="#fff"
													/>
												</g>
											</svg>
										</span>
									</PopoverButton>

									<PopoverOverlay
										class="z-1 fixed inset-0 bg-black opacity-30"
										:class="[
											'top-[75px]',
											isReduced ? 'md:top-[75px]' : 'md:top-[107px]',
										]"
									/>

									<Transition
										enter-active-class="transition duration-200"
										enter-from-class="opacity-0"
										enter-to-class="opacity-100"
										leave-active-class="transition duration-200"
										leave-from-class="opacity-100"
										leave-to-class="opacity-0"
									>
										<PopoverPanel
											class="z-20 mt-2 w-full bg-white py-16 shadow-md md:absolute md:left-0"
										>
											<IntMegaDropdown
												:sub-navigations="page.subNavigations"
												:promos="page.promos"
											>
											</IntMegaDropdown>
										</PopoverPanel>
									</Transition>
								</Popover>
								<a
									v-else
									:href="page.url"
									:target="page.target"
									class="menu-item lg:text-md relative h-10 text-sm uppercase outline-none hover:underline focus:underline xl:text-base"
								>
									{{ page.title }}
								</a>
							</PopoverGroup>
						</div>
					</div>

					<div class="col-span-8 flex-row self-center text-right lg:col-span-2">
						<PopoverGroup v-if="searchPage" class="inline-block">
							<Popover class="group inline-block">
								<PopoverButton class="inline-block h-10 w-10" @click="closeMenu">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h:[18px] inline-block w-[18px] hover:fill-blue-600 group-data-[headlessui-state=open]:fill-blue-600 sm:h-5 sm:w-5"
										width="20.49"
										height="20.592"
										fill="#a5a5a5"
									>
										<use :href="iconHref('SearchIcon')" />
									</svg>

									<span class="sr-only">{{ searchLabel }}</span>
								</PopoverButton>

								<PopoverOverlay
									class="z-1 fixed inset-0 bg-black opacity-30"
									:class="[
										'top-[75px]',
										isReduced ? 'md:top-[75px]' : 'md:top-[107px]',
									]"
								/>

								<Transition>
									<PopoverPanel
										class="absolute left-0 w-full bg-white p-5 py-14 text-center"
										focus
									>
										<form
											class="inline-block w-full xl:w-4/6"
											:action="searchPage"
											method="get"
										>
											<div class="flex">
												<div class="relative w-full">
													<input
														type="search"
														name="searchTerm"
														class="z-20 block w-full rounded-lg border-2 border-gray-100 bg-gray-100 p-3 text-base focus:border-blue focus:outline-none focus:ring-blue"
														:placeholder="searchPlaceholder"
														required
													/>
													<button
														type="submit"
														class="absolute top-1 right-1 rounded-r-lg bg-gray-100 p-3"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="hover:fill-blue-600"
															width="20.49"
															height="20.592"
															fill="#a5a5a5"
														>
															<use :href="iconHref('SearchIcon')" />
														</svg>

														<span class="sr-only">{{
															searchLabel
														}}</span>
													</button>
												</div>
											</div>
										</form>
									</PopoverPanel>
								</Transition>
							</Popover>
						</PopoverGroup>

						<a
							v-if="contactPage"
							:href="contactPage"
							class="focus:outline-auto ml-2 p-2 focus:outline-black lg:ml-0 xl:ml-2"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h:[18px] inline-block w-[18px] hover:fill-blue-600 group-data-[headlessui-state=open]:fill-blue-600 sm:h-5 sm:w-5"
								width="20.49"
								height="20.592"
								fill="#a5a5a5"
							>
								<use :href="iconHref('PhoneIcon')" />
							</svg>

							<span class="sr-only">{{ contactLabel }}</span>
						</a>

						<PopoverGroup v-if="showAccountPanel" class="inline-block">
							<Popover class="group inline-block lg:relative">
								<PopoverButton
									class="ml-2 h-10 w-10 lg:ml-0 xl:ml-2"
									@click="closeMenu"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h:[18px] inline-block w-[15px] hover:fill-blue-600 group-data-[headlessui-state=open]:fill-blue-600 sm:h-5 sm:w-[17px]"
										width="20.49"
										height="20.592"
										fill="#a5a5a5"
									>
										<use :href="iconHref('UserIcon')" />
									</svg>

									<span class="sr-only">{{ accountButtonLabel }}</span>
								</PopoverButton>

								<PopoverOverlay
									class="z-1 fixed inset-0 bg-black opacity-30"
									:class="[
										'top-[75px]',
										isReduced ? 'md:top-[75px]' : 'md:top-[107px]',
									]"
								/>

								<Transition>
									<PopoverPanel
										v-slot="{ close }"
										class="absolute left-0 mt-10 flex w-full flex-row items-center justify-center px-6 md:mt-14 lg:left-auto lg:-right-10 lg:mt-3 lg:w-auto lg:px-0 lg:text-right"
									>
										<div
											class="relative w-96 rounded-3xl bg-white p-8 shadow-md"
										>
											<svg
												class="-top-[20px] hidden lg:absolute lg:inline-block lg:group-data-[headlessui-state=open]:inline-block"
												style="right: 2.52rem"
												xmlns="http://www.w3.org/2000/svg"
												width="38"
												height="22"
												viewBox="0 0 38 22"
											>
												<defs>
													<filter
														id="Polygon_1"
														x="0"
														y="0"
														width="38"
														height="22"
														filterUnits="userSpaceOnUse"
													>
														<feOffset dy="-2" input="SourceAlpha" />
														<feGaussianBlur
															stdDeviation="1"
															result="blur"
														/>
														<feFlood flood-opacity="0.161" />
														<feComposite operator="in" in2="blur" />
														<feComposite in="SourceGraphic" />
													</filter>
												</defs>

												<g
													transform="matrix(1, 0, 0, 1, 0, 0)"
													filter="url(#Polygon_1)"
												>
													<path
														id="Polygon_1-2"
														data-name="Polygon 1"
														d="M16,0,32,16H0Z"
														transform="translate(3 5)"
														fill="#fff"
													/>
												</g>
											</svg>

											<button @click="closePopover(close)">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="18"
													height="18"
													fill="#A5A5A5"
												>
													<use :href="iconHref('PlusIcon')" />
												</svg>

												<span class="sr-only">{{ closeLabel }}</span>
											</button>

											<h6
												v-if="accountTitle"
												class="mb-4 text-center text-2xl"
											>
												{{ accountTitle }}
											</h6>

											<div v-if="hasAccountLinks">
												<ul>
													<li
														v-for="(link, index) in accountLinks"
														:key="'accountlink' + index"
													>
														<a
															:href="link.url"
															:target="link.target"
															class="block py-2 text-left text-gray hover:text-black"
														>
															{{ link.name }}
														</a>
													</li>
												</ul>
											</div>

											<div v-if="loginPage || accountFooterPage">
												<int-nav-button
													v-if="loginPage"
													type="anchor"
													:href="loginPage"
													color="tint"
													fit-content
													class="text-center uppercase hover:no-underline focus:no-underline focus:outline-black"
												>
													{{ loginPageTitle }}
												</int-nav-button>

												<div
													class="border-t-1 mt-10 border-blue"
													v-if="accountFooterPage"
												>
													<h6
														v-if="accountFooterTitle"
														class="mb-2 text-center text-lg"
													>
														{{ accountFooterTitle }}
													</h6>

													<int-nav-button
														type="anchor"
														:href="accountFooterPage"
														color="tint"
														fit-content
														class="text-center uppercase hover:no-underline focus:no-underline focus:outline-black"
													>
														{{ accountFooterPageTitle }}
													</int-nav-button>
												</div>
											</div>
										</div>
									</PopoverPanel>
								</Transition>
							</Popover>
						</PopoverGroup>

						<IntWishlistAccount
							v-if="showWishlistPanel"
							header-id="headerDiv"
							:wishlist-list-url="wishlistListUrl"
							:wishlist-detail-url="wishlistDetailUrl"
							:header-is-reduced="isReduced"
						>
						</IntWishlistAccount>
						<IntWishlistPopup
							v-if="showWishlistPanel"
							:wishlist-list-url="wishlistListUrl"
							:wishlist-detail-url="wishlistDetailUrl"
						></IntWishlistPopup>

						<a
							v-if="isFactoryShop && basketPage"
							:href="basketPage"
							class="focus:outline-auto relative ml-2 p-2 focus:outline-black lg:ml-0 xl:ml-2"
						>
							<BasketLogo
								class="inline-block h-6 w-6 fill-gray-500 hover:fill-blue-600"
								role="img"
								aria-hidden="true"
							></BasketLogo>

							<span
								v-if="basketCount > 0"
								:class="[
									'absolute top-0 font-semibold text-blue',
									basketCount >= 10 ? '-right-3' : '-right-1',
								]"
								>{{ basketCount }}</span
							>

							<span class="sr-only">{{ basketLabel }}</span>
						</a>

						<IntBasketPopup
							v-if="isFactoryShop && basketPage"
							:basket-page-url="basketPage"
						>
						</IntBasketPopup>
					</div>
				</div>
			</Popover>

			<Popover
				v-if="mobileMenuOpen"
				class="relative top-0 h-screen w-full bg-white lg:hidden"
			>
				<PopoverGroup>
					<Transition
						enter-active-class="transition duration-200"
						enter-from-class="opacity-0"
						enter-to-class="opacity-100"
						leave-active-class="transition duration-200"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
					>
						<PopoverPanel static class="panel relative h-screen">
							<Popover
								v-for="page in pages"
								class="popover bg-white data-[headlessui-state=open]:absolute data-[headlessui-state=open]:top-0 data-[headlessui-state=open]:z-50 data-[headlessui-state=open]:min-h-screen data-[headlessui-state=open]:w-full"
							>
								<PopoverGroup
									v-if="page.subNavigations && page.subNavigations.length > 0"
									class="popovergroup relative block w-full"
								>
									<PopoverButton
										class="popoverbutton w-100 border-sand block w-full border-t-[1px] border-b-[1px] px-5 py-3 text-left uppercase"
										>{{ page.title }}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											class="float-right inline rotate-180"
										>
											<use :href="iconHref('ChevronLeftIcon')" />
										</svg>
									</PopoverButton>

									<Transition
										enter-active-class="transition duration-200"
										enter-from-class="opacity-0"
										enter-to-class="opacity-100"
										leave-active-class="transition duration-200"
										leave-from-class="opacity-100"
										leave-to-class="opacity-0"
									>
										<PopoverPanel
											class="popoverpanel absolute top-0 block h-screen w-full bg-white"
											v-slot="{ close }"
										>
											<button
												class="w-100 border-sand block w-full border-t-[1px] border-b-[1px] px-5 py-3 text-left align-middle uppercase"
												@click="closePopover(close)"
											>
												<span class="leading-none">
													<span class="relative -top-[2px] mr-3">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="20"
															height="20"
															class="inline"
														>
															<use
																:href="iconHref('ChevronLeftIcon')"
															/>
														</svg>
													</span>

													<span>BACK</span>
												</span>
											</button>

											<IntMobileNavigation
												v-for="subNav in page.subNavigations"
												:title="subNav.title"
												:links="subNav.links"
											>
											</IntMobileNavigation>
										</PopoverPanel>
									</Transition>
								</PopoverGroup>
								<a
									v-else-if="page.url"
									:href="page.url"
									:target="page.target"
									class="block border-y-[1px] px-5 py-3 uppercase"
								>
									{{ page.title }}
								</a>
							</Popover>
						</PopoverPanel>
					</Transition>
				</PopoverGroup>
			</Popover>
		</div>
	</header>

	<IntWishlistModal
		v-if="!isFactoryShop"
		:wishlist-list-url="wishlistListUrl"
		:wishlist-detail-url="wishlistDetailUrl"
	></IntWishlistModal>

	<div v-if="$slots.infobanner">
		<slot name="infobanner"></slot>	
	</div>

	<div
		v-if="$slots.breadcrumb"
		:class="[
			'transition-top hidden w-full lg:block',
			isReduced ? 'top-[75px]' : 'top-[107px]',
			$slots.infobanner ? '' : 'absolute left-0 z-40',
		]"
	>
		<slot name="breadcrumb"></slot>
	</div>
</template>

<script setup>
import { PopoverGroup, Popover, PopoverOverlay, PopoverButton, PopoverPanel } from "@headlessui/vue"
</script>

<script>
import { mapState } from "pinia"
import { useBasketStore } from "../../stores/basket"

import ChevronLeftIcon from "../../../assets/icons/chevron-left.svg"
import CloseMenuIcon from "../../../assets/icons/close-menu.svg"
import HeartIcon from "../../../assets/icons/heart-icon.svg"
import OpenMenuIcon from "../../../assets/icons/open-menu.svg"
import PhoneIcon from "../../../assets/icons/phone-icon.svg"
import PlusIcon from "../../../assets/icons/plus-icon.svg"
import SearchIcon from "../../../assets/icons/search-icon.svg"
import UserIcon from "../../../assets/icons/user-icon.svg"

const iconMap = {
	ChevronLeftIcon: ChevronLeftIcon,
	CloseMenuIcon: CloseMenuIcon,
	HeartIcon: HeartIcon,
	OpenMenuIcon: OpenMenuIcon,
	PhoneIcon: PhoneIcon,
	PlusIcon: PlusIcon,
	SearchIcon: SearchIcon,
	UserIcon: UserIcon,
}

import IntBasketPopup from "../basket/modules/popup"
import IntMegaDropdown from "./modules/mega-dropdown"
import IntMobileNavigation from "./modules/mobile-navigation"
import IntNavButton from "../nav-button"
import IntWishlistAccount from "../wishlists/modules/account"
import IntWishlistModal from "../wishlists/modules/modal"
import IntWishlistPopup from "../wishlists/modules/popup"

import BasketLogo from "../assets/icons/Basket.vue"
import FactoryShopLogo from "../assets/icons/FactoryShopLogo.vue"
import TradeShopLogo from "../assets/icons/Logo.vue"

export default {
	name: "IntHeader",

	components: {
		IntBasketPopup,
		IntMegaDropdown,
		IntMobileNavigation,
		IntNavButton,
		IntWishlistAccount,
		IntWishlistModal,
		IntWishlistPopup,
		BasketLogo,
		FactoryShopLogo,
		TradeShopLogo,
	},

	props: {
		pages: { type: Array, required: true },
		isFactoryShop: { type: Boolean },
		homepageUrl: { type: String, default: "/" },
		searchPage: { type: String },
		searchLabel: { type: String, default: "Search" },
		searchPlaceholder: {
			type: String,
			default: "Enter keyword or product code",
		},
		contactPage: { type: String },
		contactLabel: { type: String, default: "Contact us" },
		accountButtonLabel: { type: String, default: "Login" },
		basketPage: { type: String },
		basketLabel: { type: String, default: "View your shopping basket" },
		closeLabel: { type: String, default: "Close" },
		accountTitle: { type: String, default: "Trade Login" },
		loginPage: { type: String },
		loginPageTitle: { type: String, default: "Log in" },
		accountLinks: { type: Array },
		accountFooterTitle: { type: String, default: "Don't have a login?" },
		accountFooterPage: { type: String },
		accountFooterPageTitle: { type: String, default: "Request trade login" },
		showWishlistPanel: { type: Boolean, default: false },
		wishlistListUrl: { type: String, default: "" },
		wishlistDetailUrl: { type: String, default: "" },
	},

	data() {
		return {
			isReduced: false,
			mobileMenuOpen: false,
			headerHeight: null,
		}
	},

	computed: {
		...mapState(useBasketStore, ["basketCount"]),

		hasAccountLinks() {
			return this.accountLinks && this.accountLinks.length > 0
		},

		showAccountPanel() {
			return this.hasAccountLinks || this.loginPage || this.accountFooterPage
		},
	},

	mounted() {
		window.addEventListener("scroll", this.updateHeight)
		window.addEventListener("scroll", this.reduceHeaderPaddingOnScroll)

		this.updateHeight()
	},

	methods: {
		closePopover(close) {
			close()
		},

		closeMenu() {
			this.mobileMenuOpen = false
		},

		updateHeight() {
			this.headerHeight = this.$refs.headerDiv.clientHeight
		},

		reduceHeaderPaddingOnScroll() {
			this.isReduced = window.scrollY > 0
		},

		iconHref(key) {
			if (iconMap[key]) return "#" + iconMap[key].id
			return null
		},
	},
}
</script>
