const cloneObject = (obj) => {
	return JSON.parse(JSON.stringify(obj))
}

/**
 * Method to check whether the supplied image format should be converted.
 * @returns {boolean}
 */
const cantConvertImageTypeToWebp = (src) => {
	const regex = /\.png$/i // Match files with .png extension (case-insensitive)
	return regex.test(src)
}

const isValidDecimal = (decimal) => {
	return (
		typeof decimal === "number" &&
		Number.isFinite(decimal) &&
		/^-?\d+(\.\d{1,2})?$/.test(decimal.toString())
	)
}

const debounce = (fn, wait) => {
	let debounceTimer
	return function () {
		const context = this
		const args = arguments
		clearTimeout(debounceTimer)
		debounceTimer = setTimeout(() => fn.apply(context, args), wait)
	}
}

export { cloneObject, cantConvertImageTypeToWebp, debounce, isValidDecimal }
