<template>
	<Popover v-slot="{ open }" class="group inline-block lg:relative">
		<Transition
			leave-active-class="transition-opacity duration-2000 ease-in"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<PopoverPanel
				v-if="show"
				static
				v-slot="{ close }"
				class="absolute right-0 mt-10 flex w-full flex-row items-center justify-center px-6 md:mt-14 lg:-right-4 lg:mt-7 lg:w-auto lg:px-0 lg:text-right xl:-right-9"
			>
				<div class="relative w-96 rounded-3xl bg-white p-8 shadow-md">
					<svg
						class="-top-[1.25rem] hidden lg:absolute lg:right-5 lg:inline-block lg:group-data-[headlessui-state=open]:inline-block xl:right-10"
						xmlns="http://www.w3.org/2000/svg"
						width="38"
						height="22"
						viewBox="0 0 38 22"
					>
						<defs>
							<filter
								id="Polygon_1"
								x="0"
								y="0"
								width="38"
								height="22"
								filterUnits="userSpaceOnUse"
							>
								<feOffset dy="-2" input="SourceAlpha" />
								<feGaussianBlur stdDeviation="1" result="blur" />
								<feFlood flood-opacity="0.161" />
								<feComposite operator="in" in2="blur" />
								<feComposite in="SourceGraphic" />
							</filter>
						</defs>

						<g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Polygon_1)">
							<path
								id="Polygon_1-2"
								data-name="Polygon 1"
								d="M16,0,32,16H0Z"
								transform="translate(3 5)"
								fill="#fff"
							/>
						</g>
					</svg>

					<div class="border-b-[1px] text-left">
						<h5 class="mb-1 normal-case">{{ title }}</h5>
					</div>

					<div v-if="itemUpdated" class="my-5 flex text-sm">
						<div v-if="itemUpdated.image" class="mr-5">
							<IntResponsiveImage
								:src="itemUpdated.image"
								:width="50"
								:height="50"
								generate-crop-src
								mode="BoxPad"
								sizes="50px"
							>
							</IntResponsiveImage>
						</div>

						<div class="text-left">
							<div class="font-medium">{{ itemUpdated.name }}</div>
							<div v-if="itemUpdated.size">{{ itemUpdated.size }}</div>
						</div>
					</div>

					<div>
						<IntNavButton
							type="anchor"
							:href="basketPageUrl"
							color="tint"
							fit-content
							class="text-center uppercase hover:no-underline focus:no-underline"
						>
							{{ basketPageLabel }}
						</IntNavButton>
					</div>
				</div>
			</PopoverPanel>
		</Transition>
	</Popover>
</template>

<script setup>
import { Popover, PopoverPanel } from "@headlessui/vue"
</script>

<script>
import EventBus from "../../../../bus"
import Events from "../../utils/events"

import IntNavButton from "../../../nav-button"
import IntResponsiveImage from "../../../responsive-image"

export default {
	name: "IntBasketPopup",

	components: { IntNavButton, IntResponsiveImage },

	props: {
		basketPageUrl: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: "Item added to your basket",
		},
		basketPageLabel: {
			type: String,
			default: "View basket",
		},
		// Duration to delay the fade out animation, in milliseconds
		delayFadeOut: {
			type: Number,
			default: 5000,
		},
	},

	data() {
		return {
			show: false,
			itemUpdated: {},
			fadeOutTimeout: null, // Variable to store the timeout reference
		}
	},

	created() {
		EventBus.$on(Events.basketUpdated, this.onBasketUpdatedEventDisplayPopup)
	},

	beforeDestroy() {
		clearTimeout(this.fadeOutTimeout)
	},

	methods: {
		onBasketUpdatedEventDisplayPopup(e) {
			this.clearPreviousPopupData()

			this.itemUpdated = { name: e.name, image: e.image, size: e.size }
			this.show = true

			// Cancel the previous timeout and start a new one
			clearTimeout(this.fadeOutTimeout)

			this.fadeOutTimeout = setTimeout(() => {
				this.show = false
				this.clearPreviousPopupData()
			}, this.delayFadeOut)
		},

		/**
		 * Clear the data from the previous popup, in case another update within timeout period
		 */
		clearPreviousPopupData() {
			this.itemUpdated = {}
		},
	},
}
</script>
