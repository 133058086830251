import ProductQuantity from '../../../models/ProductQuantity'
import Wishlist from '../../../models/Wishlist'

/**
 * Get the wishlist data from the element and convert it to a Map to be assigned to the store.
 * @param {string} appId - The ID of the Vue app to get the wishlist data from.
 * @returns {Map} - The wishlist data converted to a Map to be assigned to the store.
 */
const preloadWistData = (appId) => {
    let wishlistsMap = new Map()

    if (!appId) return wishlistsMap
    
    const el = document.getElementById(appId)
    if (!el) return wishlistsMap
    
    const wishlistsDataValue = el.getAttribute('wishlist-data')
    if (!wishlistsDataValue) return wishlistsMap

    let wishlistsData
    try {
        wishlistsData = JSON.parse(wishlistsDataValue)
    } catch (error) {
        console.error("Error parsing wishlist data:", error)
        return wishlistsMap
    }

    if (!wishlistsData) return wishlistsMap

    if (wishlistsData.wishlists) {
        wishlistsData.wishlists.forEach(data => {
            const { id, name, created, items, discount } = data
            const newWishlist = new Wishlist(id, name, created, null, discount)

            items.forEach(item => {
                const { productId, collectionId, quantity } = item
                const productQuantity = new ProductQuantity(productId, collectionId, quantity)
                newWishlist.add(productQuantity)
            })

            wishlistsMap.set(id, newWishlist)
        })
    }
    
    return wishlistsMap
}

export default {
    preloadWistData
}