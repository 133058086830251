import BasketProductQuantity from "../models/BasketProductQuantity"

const preloadData = (appId) => {
	let basketMap = new Map()

	if (!appId) return basketMap

	const el = document.getElementById(appId)
	if (!el) return basketMap

	const basketDataValue = el.getAttribute("basket-data")
	if (!basketDataValue) return basketMap

	let basketData
	try {
		basketData = JSON.parse(basketDataValue)
	} catch (error) {
		console.error("Error parsing basket data:", error)
		return basketMap
	}

	if (!basketData) return basketMap

	if (basketData.products) {
		basketData.products.forEach((data) => {
			const { productId, quantity } = data
			const newProduct = new BasketProductQuantity(productId, quantity)

			basketMap.set(productId, newProduct)
		})
	}

	return basketMap
}

export default {
	preloadData,
}
