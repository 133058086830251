<template>
    <picture class="flex-none overflow-hidden">
        <slot></slot>
        <img ref="responsiveImage" :src="imgSrc" :srcset="imgSrcSet" :alt="alt" :width="imgWidth" :height="imgHeight" :sizes="sizes" :loading="loading" :class="imgClass" :decoding="decoding" :referrerpolicy="referrerpolicy" />
    </picture>
</template>

<script>
  import { cantConvertImageTypeToWebp } from '../../utils'

  export default {
    name: 'IntResponsiveImage',
  
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: ''
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        sizes: {
            type: String
        },
        loading: {
            type: String,
            default: 'lazy',
            validator(value) {
                return ['lazy', 'eager'].includes(value)
            }
        },
        decoding: {
            type: String,
            validator(value) {
                return ['sync', 'async', 'auto'].includes(value)
            }
        },
        referrerpolicy: {
            type: String,
            validator(value) {
                return [
                    'no-referrer',
                    'no-referrer-when-downgrade',
                    'origin',
                    'origin-when-cross-origin',
                    'same-origin',
                    'strict-origin',
                    'strict-origin-when-cross-origin',
                    'unsafe-url'
                ].includes(value)
            }
        },
        class: {
            type: String
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        generateCropSrc: {
            type: Boolean,
            default: false
        },
        generateCropSrcSet: {
            type: Boolean,
            default: false
        },
        ratio: {
            type: Number
        },
        mode: {
            type: String
        },
        quality: {
            type: Number,
            default: 80
        },
        focalPoint: {
            type: String || Object  
        },
        anchor: {
            type: String || Object  
        },
        format: { type: String },
        srcSet: {
            type: String
        },
        minWidth: {
            type: Number
        },
        maxWidth: {
            type: Number,
            default: 1920
        },
        stepSize: {
            type: Number,
            default: 160
        },
        bgColour: {
          type: String
        }
    },
    
    computed: {
        imgClass() {
            var classes = [];
            if (this.fullWidth === true) {
                classes.push('w-full')
            }
            if (this.class) {
                classes.push(this.class)
            }

            return classes.join(' ')
        },
        imgSrc() {
            if (!this.src) {
                return null
            }

            if (this.generateCropSrc) {
                return this.generateSrc({
                    src: this.src,
                    width: this.width,
                    height: this.height,
                    ratio: this.ratio,
                    mode: this.mode,
                    quality: this.quality,
                    focalPoint: this.focalPoint,
                    anchor: this.anchor,
                    bgColour: this.bgColour
                })
            }

            return this.src
        },
        imgSrcSet() {
            if (this.srcSet) {
                return this.srcSet
            }

            if (this.generateCropSrcSet && this.maxWidth) {
                return this.generateSourceSet({
                    src: this.src,
                    width: this.width,
                    height: this.height,
                    ratio: this.ratio,
                    mode: this.mode,
                    quality: this.quality,
                    focalPoint: this.focalPoint,
                    anchor: this.anchor,
                    minWidth: this.minWidth,
                    maxWidth: this.maxWidth,
                    stepSize: this.stepSize,
                    bgColour: this.bgColour
                })
            }
        },
        imgWidth() {
            if (this.width)
                return this.width

            if (this.ratio)
                return Math.round(this.height / this.ratio)

            return null
        },
        imgHeight() {
            if (this.height)
                return this.height

            if (this.ratio)
                return Math.round(this.ratio * this.width)

            return null
        },

      /**
       * Method to calculate whether an image should be converted to webp, which is the default format setting.
       * PNGs should NOT be converted to webp as this INCREASES the filesize.
       * @returns {null|string}
       */
      calculatedFormat() {
          // If a specific format is requested, return the requested format
          if (this.format) return this.format
        
          // If the image is of a type that should NOT be converted to webp, return null
          if (cantConvertImageTypeToWebp(this.src)) return null
        
          return 'webp'
      }
    },
    
    methods: {
        generateSrc({
            src,
            width,
            height,
            ratio,
            mode,
            quality,
            focalPoint,
            anchor,
            bgColour
        }) {
            if (!src) {
                return null
            }

            if (!width && !height && !ratio) {
                console.info("No dimensions supplied for image crop", src)
                return src
            }

            const imageIsCompatible = src.indexOf('.svg') < 0

            if (!imageIsCompatible) {
                console.info("SVG is not compatible for image crop", src)
                return src
            }

            // TODO: Optimise - split into smaller functions
            const imageSrcParts = []

          // Supporting ImageSharp query string values
          // https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html

            if (width) {
                imageSrcParts.push('width=' + width)
            }

            if (!width && height && ratio) {
                width = Math.round(height / ratio)
                imageSrcParts.push('width=' + width)
            }

            if (height) {
                imageSrcParts.push('height=' + height)
            }

            if (!height && width && ratio) {
                height = Math.round(width * ratio)
                imageSrcParts.push('height=' + height)
            }

            // https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html#format
            if (this.calculatedFormat !== null) {
              imageSrcParts.push('format=' + this.calculatedFormat)
            }

            if (anchor) {
                // https://docs.sixlabors.com/api/ImageSharp/SixLabors.ImageSharp.Processing.AnchorPositionMode.html
                imageSrcParts.push('ranchor=' + anchor)
            }

            if (focalPoint) {
                let center
                // Parse for focalPoint object e.g. { top: 0.1234, left: 0.5678 }
                if (typeof focalPoint === 'object') {
                    if (focalPoint.top != null && focalPoint.left != null && focalPoint.top != 0.5 && focalPoint.left != 0.5) {
                        center = `${focalPoint.top},${focalPoint.left}`
                    }
                } else if (typeof focalPoint === 'string') {
                    center = focalPoint
                }

                if (center) {
                    // https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html
                    imageSrcParts.push('rxy=' + center)
                }
            }

            if (quality) {
                imageSrcParts.push('quality=' + quality)
            }

            if (mode) {
                // https://docs.sixlabors.com/api/ImageSharp/SixLabors.ImageSharp.Processing.ResizeMode.html
                imageSrcParts.push('rmode=' + mode)
            }

            if (bgColour) {
                imageSrcParts.push('bgcolor=' + bgColour)
            }

            // Combine all image URL parts into string
            if (imageSrcParts.length) {
                return src + (src.indexOf('?') >= 0 ? '&' : '?') + imageSrcParts.join('&')
            }
  
            return src
        },

        generateSourceSet({
            src,
            width,
            height,
            ratio,
            mode,
            quality,
            focalPoint,
            anchor,
            minWidth,
            maxWidth,
            stepSize,
            bgColour,
        }) {
            const sourceSets = []
            let stepWidth = (minWidth ? minWidth : stepSize)

            if (!ratio && width && height)
            {
                ratio = height / width
            }

            while (stepWidth <= maxWidth) {
                let stepHeight = null

                if (ratio) {
                    stepHeight = Math.round(ratio * stepWidth)
                }

                const stepUrl = this.generateSrc({
                    src,
                    width: stepWidth,
                    height: stepHeight,
                    ratio,
                    mode,
                    quality,
                    focalPoint,
                    anchor,
                    bgColour
                })
                const sourceSetUrl = stepUrl + ' ' + stepWidth + 'w'
                sourceSets.push(sourceSetUrl)

                stepWidth += stepSize
            }

            return sourceSets
        }
    }
  }
</script>