<template>
	<footer class="bg-stone-300 pt-24">
		<div class="mx-auto mb-0 px-5 2xl:container lg:mb-3 lg:px-8 xl:px-10">
			<div class="grid grid-flow-row grid-cols-12 grid-rows-1">
				<div class="col-span-12">
					<div class="pb-10">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="84"
							fill="#2F2F2F"
							height="30"
						>
							<use :href="iconHref('Logo')" />
						</svg>
					</div>
				</div>

				<div class="col-span-12 md:col-span-7">
					<IntAddress
						:address-lines="addressLines"
						:phone="phone"
						:email="email"
						:social-links="socialLinks"
					></IntAddress>
				</div>

				<div class="col-span-12 md:col-span-5">
					<div class="grid grid-flow-row grid-cols-12 grid-rows-1">
						<div v-if="hasCompanyLinks" class="col-span-12 lg:col-span-6">
							<ul class="text-xs uppercase leading-10 md:text-sm md:leading-9">
								<li
									v-for="(link, index) in companyLinks"
									:key="'companylink' + index"
								>
									<a :href="link.url" :target="link.target">{{ link.name }}</a>
								</li>
							</ul>
						</div>

						<div v-if="hasProductLinks" class="col-span-12 lg:col-span-6">
							<ul class="text-xs uppercase leading-10 md:text-sm md:leading-9">
								<li
									v-for="(link, index) in productLinks"
									:key="'productlink' + index"
								>
									<a :href="link.url" :target="link.target">{{ link.name }}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="col-span-12 mt-4 md:col-span-7">
					<span v-if="hasPromoLinks" v-for="link in promoLinks" class="inline">
						<int-nav-button
							type="anchor"
							:href="link.url"
							:target="link.target"
							class="mr-3 mb-4 min-w-full border-2 text-center uppercase hover:no-underline focus:no-underline md:mr-4 md:min-w-fit"
							color="inversed"
						>
							{{ link.name }}
						</int-nav-button>
					</span>
				</div>

				<div v-if="!!$slots.default" class="col-span-12 md:col-span-5 md:mt-4">
					<slot></slot>
				</div>
			</div>
		</div>

		<div
			v-if="legalText || hasLegalLinks"
			class="mt-10 bg-stone py-10 text-xs md:text-sm lg:mt-20"
		>
			<div class="mx-auto mb-0 px-5 2xl:container lg:mb-3 lg:px-8 xl:px-10">
				<div class="grid grid-flow-row grid-rows-1">
					<div class="col-span-12 grid lg:block">
						<span v-if="legalText" class="order-last mr-10 uppercase lg:order-first">{{
							legalText
						}}</span>
						<span v-if="hasLegalLinks">
							<span
								v-for="(link, index) in legalLinks"
								:key="'legallink' + index"
								class="mb-5 block uppercase lg:mb-0 lg:inline-block"
							>
								<a :href="link.url" :target="link.target" class="link mr-5">{{
									link.name
								}}</a>
							</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import Logo from "../../../assets/icons/logomark.svg"

import IntAddress from "../address"
import IntNavButton from "../nav-button"

const iconMap = {
	Logo: Logo,
}

export default {
	components: { IntAddress, IntNavButton },

	name: "IntFooter",

	props: {
		addressLines: { type: Array },
		phone: { type: String },
		email: { type: String },
		socialLinks: { type: Array },
		promoLinks: { type: Array },
		companyLinks: { type: Array },
		productLinks: { type: Array },
		legalText: { type: String },
		legalLinks: { type: Array },
	},

	computed: {
		hasPromoLinks() {
			return this.promoLinks && this.promoLinks.length > 0
		},

		hasCompanyLinks() {
			return this.companyLinks && this.companyLinks.length > 0
		},

		hasProductLinks() {
			return this.productLinks && this.productLinks.length > 0
		},

		hasLegalLinks() {
			return this.legalLinks && this.legalLinks.length > 0
		},
	},

	methods: {
		iconHref(key) {
			if (iconMap[key]) return "#" + iconMap[key].id

			return null
		},
	},
}
</script>
